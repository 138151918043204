@font-face {
  font-family: 'CASLON';
  src: url("https://static1.squarespace.com/static/5d00ba0c96365b000104ac5f/t/5d011000a57ed1000163a8ec/1560350720922/CaslonAntique-Bold.ttf");
}

.App {
  text-align: center;
  /* background-color: #000000; */
  width: 100%;
  height: 240px;
}

.header {
  display: flex;
  flex-direction: column;
  font-size: calc(40px + 2vmin);
  color: white;
}

.progress-title {
  font-family: CASLON, serif;
  height: 54px;
  margin-left: 10px;
  margin-right: 10px;
}

.progress-container {
  height: 41px;
  width: 1739px;
  background: url("assets/bar_background.png") no-repeat;
  background-size: auto;
  padding: 58px 78px;
  position: relative;
}

.progress-container-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 113px;
  height: 173px;
  z-index: 20;
  background: url("assets/skull_left.png") no-repeat;
}

.progress-container-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 113px;
  height: 173px;
  z-index: 20;
  background: url("assets/skull_right.png") no-repeat;
}

.progress-bar {
  position: relative;
  height: 41px;
  width: 100%;
  overflow: hidden;
}

.filler {
  background: url("assets/bar_fill.png") no-repeat;
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
  align-content: center;
  vertical-align: center;
  float: left;
}

.gloss {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: url("assets/bar_gloss.png") no-repeat;
}

@keyframes ooze {
  0% { transform: translate(-1px); }
  50% { transform: translate(-3px); }
  100% { transform: translate(-1px); }
}

.filler-effect {
  height: 100%;
  width: 35px;
  z-index: 5;
  background: url("assets/bar_glow.png");
  float: left;
  animation: ooze 1.5s infinite;
}

.label-completed {
  color: green;
}

.tremble {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
    color: darkred;
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
    color: darkred;
  }

  30%, 50%, 70% {
    transform: translate3d(-3px, 0, 0);
    color: darkred;
  }

  40%, 60% {
    transform: translate3d(3px, 0, 0);
    color: darkred;
  }
}